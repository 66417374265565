.desktop-container {
  display: none;
}

.mobile-container {
  display: block;
}

@media (min-width: 900px) {
  .desktop-container {
    display: block;
  }

  .mobile-container {
    display: none;
  }
}