@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800&display=swap'); 

* {
    font-family: 'Mulish'; 
}
 
.font-h1, h1, .font-h2, h2, .font-h3, h3, .font-h4, h4, .font-h5, h5, .font-p, p, span, td  { 
    font-family: 'Mulish'; 
    color: var(--textColor); 
}
    
.font-h1, h1 { 
    font-size: 2.25rem; 
    font-weight: 600; 
    margin: 30px 0;
    @media only screen and (max-width: 768px) {
        font-size: 1.75rem;
    }
} 
 
.font-h2, h2 { 
    font-size: 1.5rem; 
    @media only screen and (max-width: 768px) {
        font-size: 1rem;
    }
    font-weight: 800; 
    margin: 25px 0; 
} 

.font-h3, h3 { 
    font-size: 1.25rem; 
    font-weight: 700; 
    margin: 20px 0;
    @media only screen and (max-width: 768px) {
        font-size: 1rem;
    }
} 

.font-h4, h4 { 
    font-size: 1rem; 
    font-weight: 700; 
    margin: 15px 0;
} 

.font-h5, p, span, td { 
    font-size: 1rem; 
    font-weight: 400; 
} 
 
Route {
    width: 100%;
}

button:focus {
    outline: 2px solid black !important;
}

a {
    width: auto;
    height: auto;
}
.page-padding{
    margin: auto;
    width: 50%;
    @media only screen and (max-width: 1024px) {
        width: 75%;
    }
    @media only screen and (max-width: 768px) {
        width: 95%;
    }
}
.spinner-animation {
    -webkit-animation: icon-spin 2s infinite linear;
            animation: icon-spin 2s infinite linear;
  }
  
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }