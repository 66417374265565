@import './style/globals.scss';

.Header {
  height: 20%;
}


.main {
  width: 100%;
  height: 80%;
  padding-top: 1rem;
}

.privacy-policy a{
  color: blue
}
.windmill-table{
  .w-full.overflow-x-auto{
    overflow-x: hidden;
  }
  table{
    white-space: normal;
  }
}
.table-add-button{
  svg{
    height: 3em;
    width: 3em;
  }
}
.radio-buttons{
  .border-teacher{
    border-color: #FF9D86 !important;
  }
}
@media print { 
  /* All your print styles go here */
  #header, #footer, #nav { display: none !important; } 
  .App{ background-color: white;}
  .page-padding{ padding: unset; }
  .main-wrapper{ width: 100%; }
 }
